@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300&display=swap);
body {
    font-family: 'Montserrat', sans-serif !important;
}

.ui.inverted.header {
    font-family: 'Montserrat', sans-serif !important;
}

img.ui.image {
    -webkit-filter: invert(60%);
            filter: invert(60%);
}

i.sidebar.big.link.icon {
    position: fixed;
    top: 30;
    z-index: 100;
    background-color: #333333;
}
